import React, { Component } from "react";

// css
import "./PrivacyPolicy.css";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="privacy_policy_container">
        <h1>Privacy Policy for Smilepay</h1>
        <p>
          At Smilepay, one of our main priorities is the privacy of our
          visitors. This Privacy Policy document contains types of information
          that is collected and recorded by Smilepay and how we use it. If you
          have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>
        <h2>Log Files</h2>
        <p>
          Smilepay follows a standard procedure of using log files. These files
          log visitors when they use app. The information collected by log files
          include internet protocol (IP) addresses, browser type, Internet
          Service Provider (ISP), date and time stamp, referring/exit pages, and
          possibly the number of clicks. These are not linked to any information
          that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the app, tracking users' movement on
          the app, and gathering demographic information.
        </p>
        <h2>Children's Information</h2>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
          <br />
          <br />
          Smilepay does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our App, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
        <h2>What rights you have over your data</h2>
        <p>
          If you have an account on this site, or have left comments, you can
          request to receive an exported file of the personal data we hold about
          you, including any data you have provided to us. You can also request
          that we erase any personal data we hold about you. This does not
          include any data we are obliged to keep for administrative, legal, or
          security purposes.
        </p>
        <h2>Online Privacy Policy Only</h2>
        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our App with regards to the information that they
          shared and/or collect in Smilepay. This policy is not applicable to
          any information collected offline or via channels other than this app.
          Our Privacy Policy was created with the help of the App Privacy Policy
          Generator from
          <a href="https://App-Privacy-Policy.com"> App-Privacy-Policy.com</a>
        </p>
        <h2>Consent</h2>
        <p>
          By using our app, you hereby consent to our Privacy Policy and agree
          to its Terms and Conditions.
        </p>
      </div>
    );
  }
}

export default PrivacyPolicy;
