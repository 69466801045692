import React, { Component } from "react";

// css
import "./Home.css";

// sub sections
import Main from "../SubSections/Main/Main";
import Cards from "../SubSections/Cards/Cards";
import Bottom from "../SubSections/BottomNav/Bottom";

class Home extends Component {
  render() {
    return (
      <div className="containerH">
        <Main />
        <Cards />
        <Bottom />
      </div>
    );
  }
}

export default Home;
