import React, { Component } from "react";

// css
import "./Bottom.css";

//svg
import logo from "../../svg/SmilePay.svg";

class Bottom extends Component {
  state = {};
  render() {
    return (
      <div className="bodyBN">
        <div className="contact-usBN">
          <div className="centerBN" style={{ flex: 0.3 }}>
            <span className="contact-titleC">Contact Us</span>
            <a className="linksBN" href="mailto: info@smilepay.app">
              <span>info@smilepay.app</span>
            </a>
          </div>
          <div className="centerBN" style={{ flex: 0.6 }}>
            <span className="contact-titleC">Follow Us</span>
            <div className="linksBN">
              <section>
                <a href="https://twitter.com/smilepay_global" target="_blank">
                  <span>X(Twitter)</span>
                </a>
                <a href="https://t.me/SmilepayGlobal" target="_blank">
                  <span>Telegram</span>
                </a>
              </section>
              <section>
                <a
                  href="https://www.facebook.com/SmilepayGlobal"
                  target="_blank"
                >
                  <span>Facebook</span>
                </a>
                <a
                  href="https://www.instagram.com/smilepay_global/"
                  target="_blank"
                >
                  <span>Instagram</span>
                </a>
              </section>
              <section>
                <a
                  href="https://www.linkedin.com/company/smilepay-global"
                  target="_blank"
                >
                  <span>Linkedin</span>
                </a>
                <a href="https://medium.com/@Smilepay" target="_blank">
                  <span>Medium</span>
                </a>
              </section>
              <section />
            </div>
          </div>
        </div>
        <img className="logoBN" src={logo} />
      </div>
    );
  }
}

export default Bottom;
