import React, { Component } from "react";

// css
import "./Main.css";

// svg
import smilePayLogo from "../../svg/SPLogowhite.svg";
import phone from "../../svg/phone.png";
import googlePlay from "../../svg/playstore.png";
import appStore from "../../svg/applestore.png";
import appGallery from "../../svg/huawei.png";
import linkQr from "../../svg/link_qr.svg";

class Main extends Component {
  state = {};
  render() {
    return (
      <div className="bodyH">
        <div className="body-centerH">
          <div className="body-containerH">
            <div className="center-leftH ">
              <section>
                <img className="smile-logoH" src={smilePayLogo} />
                <h1 className="title-textH">
                  Send money like
                  <br />a text
                </h1>
                <span className="body-textH">
                  Pay and get paid globally. All you need is a smile tag or a
                  bank account.
                </span>

                <div className="link_containerH">
                  <div className="buttons_containerH">
                    <a
                      href="https://apps.apple.com/us/app/smilepay-send-money-to-africa/id6447351968"
                      target="_blank"
                    >
                      <div
                        style={{ marginTop: 0 }}
                        className="button-containerH unselectable"
                      >
                        <img src={appStore} />
                        <span className="button-textH">App Store</span>
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=app.smilepay"
                      target="_blank"
                    >
                      <div className="button-containerH unselectable">
                        <img src={googlePlay} />
                        <span className="button-textH">Play Store</span>
                      </div>
                    </a>
                    <a
                      href="https://appgallery.huawei.com/app/C107063279"
                      target="_blank"
                    >
                      <div className="button-containerH unselectable">
                        <img src={appGallery} />
                        <span className="button-textH">Appgallery</span>
                      </div>
                    </a>
                  </div>
                  <div className="qr_containerH">
                    <div>
                      <img className="qr_image" src={linkQr} />
                    </div>
                    <span>
                      ☝️ <br /> Scan to download <br /> Smilepay app
                    </span>
                  </div>
                </div>
              </section>
            </div>
            <div className="center-rightH">
              <img src={phone} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
