import React, { Component } from "react";

// css
import "./Cards.css";

// svg
import eFlag from "../../svg/ethiopia.png";
import usaFlag from "../../svg/united-states.png";
import googlePlay from "../../svg/Google_Play_Icon.svg";
import appStore from "../../svg/App_Store_Icon .svg";
import appGallery from "../../svg/huawei_white.png";
import visaIE from "../../svg/visaIE.jpg";
import abyssiniaLogo from "../../svg/abyssiniaLogoAndName.png";
import geezSms from "../../svg/geezDark.png";
import kazanaGroup from "../../svg/kazanaGroup.png";
import mclovin from "../../svg/mclovin.gif";
import billPayment from "../../svg/billPayment.gif";

import YoutubeEmbed from "../../util/YoutubeEmbed";

class Cards extends Component {
  state = {};
  render() {
    return (
      <div className="bodyC">
        <div className="cards-containerC">
          <div style={{ backgroundColor: "black" }} className="cardC">
            <span style={{ color: "white" }} className="card-titleC">
              Send & Recieve Money
            </span>
            <span style={{ color: "white" }} className="card-bodyC">
              Send money to any Smilepay users or bank accounts across borders.
              Its instant & cheap.
            </span>
          </div>
          <div style={{ backgroundColor: "#f5efd8" }} className="cardC">
            <span style={{ color: "black" }} className="card-titleC">
              Request Money
            </span>
            <span style={{ color: "black" }} className="card-bodyC">
              By downloading Smilepay app, request money form your friends an
              family abroad.
            </span>
          </div>
        </div>

        <div className="cards-containerC">
          <div style={{ backgroundColor: "#DDEEE6" }} className="cardC">
            <span style={{ color: "black" }} className="card-titleC">
              Pay Bills For Anyone!
            </span>
            <span style={{ color: "black" }} className="card-bodyC">
              Pay utility bills for your friends and family back home from where
              you are.
            </span>
          </div>
          <img src={billPayment} className="globeC" />
        </div>
        <div className="cards-containerC">
          <div style={{ backgroundColor: "#E0EEFE" }} className="cardC">
            <span style={{ color: "black" }} className="card-titleC">
              Buy Airtime
            </span>
            <span style={{ color: "black" }} className="card-bodyC">
              For your friends and family back home from where you are.
            </span>
          </div>
          <div style={{ backgroundColor: "#eccdcd" }} className="cardC">
            <span style={{ color: "black" }} className="card-titleC">
              Donate
            </span>
            <span style={{ color: "black" }} className="card-bodyC">
              Donate to any causes that you support.
            </span>
          </div>
        </div>
        <div className="cards-containerC">
          <div style={{ backgroundColor: "black" }} className="cardC">
            <span style={{ color: "white" }} className="card-titleC">
              Verify ID
            </span>
            <span style={{ color: "white" }} className="card-bodyC">
              Quick and simple KYC/AML verification proccess. Takes less than 2
              minutes
            </span>
          </div>
          <img src={mclovin} className="globeC" />
        </div>

        <div className="countriesC">
          <div>
            <span style={{ color: "black" }} className="card-titleC">
              Available in
            </span>
            <section>
              <img src={eFlag} />
              <img src={usaFlag} />
            </section>
          </div>
          <div style={{ alignItems: "center" }} className="ccc">
            <a
              href="https://apps.apple.com/us/app/smilepay-send-money-to-africa/id6447351968"
              target="_blank"
            >
              <section className="comming-soonC">
                <img src={appStore} />
                <span>App Store</span>
              </section>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=app.smilepay"
              target="_blank"
            >
              <section className="comming-soonC">
                <img src={googlePlay} />
                <span>Play Store</span>
              </section>
            </a>
            <a
              href="https://appgallery.huawei.com/app/C107063279"
              target="_blank"
            >
              <section className="comming-soonC">
                <img src={appGallery} />
                <span>Appgallery</span>
              </section>
            </a>
          </div>
        </div>
        <div className="currenciesC">
          <div>
            <span style={{ color: "black" }} className="card-titleC">
              Supported Currencies
            </span>
            <section>
              <span style={{ color: "black" }} className="card-bodyC">
                USD ($)
              </span>
              <span
                style={{
                  color: "black",
                  marginLeft: "3vw",
                }}
                className="card-bodyC"
              >
                ETB (ብር)
              </span>
            </section>
          </div>
        </div>
        <div className="visaIEC">
          <div style={{ padding: "0px" }} className="visaCardC">
            <YoutubeEmbed embedId="9AcX0iuEtgY?start=4430" />
          </div>
          <div className="visaCardC">
            <img src={visaIE} style={{ height: "400px" }}/>
          </div>
        </div>
        <div className="partnersC">
          <div className="partnersLeftC">
            <span style={{ color: "black" }} className="card-titleC">
              Offical Partners
            </span>
          </div>

          {/* protrait version */}
          <section className="partnersMC">
            <section
              style={{ backgroundColor: "#ddeee6" }}
              className="partnerLogoMC"
            >
              <img className="partnerIcon2C" src={abyssiniaLogo} />
            </section>
            <section
              style={{ backgroundColor: "#dedede" }}
              className="partnerLogoMC"
            >
              <img className="partnerIconC" src={geezSms} />
            </section>
            <section
              style={{ backgroundColor: "#eccdcd" }}
              className="partnerLogoMC"
            >
              <img className="partnerIconC" src={kazanaGroup} />
            </section>
          </section>

          {/* landscape version */}
          <div className="partnersRightC">
            <div>
              <section
                style={{ backgroundColor: "#ddeee6" }}
                className="partnerLogoC"
              >
                <img className="partnerIcon2C" src={abyssiniaLogo} />
              </section>
              <section
                style={{ backgroundColor: "#dedede" }}
                className="partnerLogoC"
              ></section>
            </div>
            <div>
              <section
                style={{ backgroundColor: "#E0EEFE" }}
                className="partnerLogoC"
              >
                <img className="partnerIconC" src={geezSms} />
              </section>
              <section
                style={{ backgroundColor: "#dedede" }}
                className="partnerLogoC"
              ></section>
            </div>
            <div>
              <section
                style={{ backgroundColor: "#eccdcd" }}
                className="partnerLogoC"
              >
                <img className="partnerIconC" src={kazanaGroup} />
              </section>
              <section
                style={{ backgroundColor: "#dedede" }}
                className="partnerLogoC"
              ></section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cards;
